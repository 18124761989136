<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/onboarding')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ uuid ? "Edit" : "Add" }} Onboarding
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="onboardingForm" @submit="save()">
                      <div class="row">
                        <!-- <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Order</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="ordering"
                                class="form-control"
                                placeholder="Ordering"
                                v-model="detail.ordering"
                                rules="required:ordering"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="ordering" class="text-danger" />
                            </div>
                          </div> -->

                        <div class="form-group col-md-6">
                          <label for="inputFileType">File Type</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputFileType"
                            name="file_type"
                            v-model="detail.type"
                            rules="required:type"
                          >
                            <option value="" disabled>Select File Type</option>
                            <option value="CURSOR">Cursor</option>
                            <option value="DATE">Date</option>
                            <option value="PDF">PDF</option>
                            <option value="VIDEO">Video</option>
                            <option value="SINGLE_SELECTION">
                              Single Selection
                            </option>
                          </Field>
                          <ErrorMessage name="file_type" class="text-danger" />
                        </div>
                        <div
                          class="col-lg-6 col-sm-6"
                          v-if="
                            detail.type == 'SINGLE_SELECTION' ||
                            detail.type == 'DATE' ||
                            detail.type == 'CURSOR'
                          "
                        >
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="title"
                              class="form-control"
                              placeholder="Title"
                              v-model="detail.title"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>

                        <div
                          class="col-lg-6 col-sm-6 row mb-4"
                          v-if="
                            detail.type == 'SINGLE_SELECTION' ||
                            detail.type == 'CURSOR'
                          "
                        >
                          <div class="col-lg-11">
                            <label>Option</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="option"
                              class="form-control"
                              placeholder="Option"
                              v-model="option"
                            />
                          </div>
                          <div class="col-lg-1" style="top: 31px">
                            <i
                              v-on:click="saveOption()"
                              class="fa fa-plus cursor-pointer"
                              aria-hidden="true"
                              style="
                                font-size: 20px;
                                height: 30px;
                                padding: 6px;
                              "
                            ></i>
                          </div>
                        </div>

                        <div
                          class="table-responsive col-lg-12"
                          v-if="
                            detail.type == 'SINGLE_SELECTION' ||
                            detail.type == 'CURSOR'
                          "
                        >
                          <table class="table">
                            <thead>
                              <tr role="row">
                                <th>Title</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in detail.list"
                                :key="index"
                              >
                                <td>
                                  <Field
                                    :id="'title_' + index"
                                    type="text"
                                    :name="'title_' + index"
                                    class="form-control"
                                    placeholder="Option"
                                    v-model="data.title"
                                  />
                                </td>
                                <td>
                                  <!-- <a
                                    href="javascript:void(0)"
                                    class="mr-2 text-dark"
                                    ><i class="bx bxs-edit-alt"></i
                                  ></a> -->
                                  <a
                                    class="mr-2 text-danger cursor-pointer"
                                    v-on:click="removeOption(index)"
                                    ><i class="bx bx-trash"></i
                                  ></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div
                          class="col-lg-6 col-sm-6"
                          v-if="detail.type == 'VIDEO' || detail.type == 'PDF'"
                        >
                          <div class="form-group">
                            <label>Content</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="textarea"
                              name="content"
                              class="form-control"
                              placeholder="Content"
                              v-model="detail.value"
                              rules="required:content"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="content" class="text-danger" />
                          </div>
                        </div>
                        <div
                          class="col-lg-6 col-sm-6"
                          v-if="detail.type == 'VIDEO'"
                        >
                          <label for="inputType">Upload File</label>

                          <div class="text-left">
                            <a
                              v-if="detail.title"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showPopUp()"
                              >Watch</a
                            >

                            <!-- <img
                              :src="detail.url ? detail.url : ''"
                              class="img-fit-cover rounded-5"
                              id="cover"
                            /> -->
                          </div>
                          <div>
                            <input
                              type="file"
                              name="img[]"
                              class="file"
                              id="preview"
                              style="display: none"
                              @change="selectedFile($event)"
                            />
                            <label for="preview"
                              ><a v-if="!detail.title" class=""
                                >Browse... &nbsp; &nbsp;</a
                              >
                              <a
                                style="
                                  float: right;
                                  cursor: pointer;
                                  text-decoration: underline;
                                "
                                v-if="detail.title"
                                class=""
                                >Change</a
                              >
                              <a v-if="!detail.title">
                                {{
                                  detail.url || fileName
                                    ? fileName
                                    : "No File Selected"
                                }}</a
                              >
                            </label>
                          </div>
                        </div>

                        <div
                          class="col-lg-12 col-sm-12 mb-3 row"
                          v-if="detail.type == 'PDF'"
                        >
                          <div class="col-md-6 mb-4">
                            <DocumentPicker
                              :src="detail.title ? detail.title : ''"
                              ref="documentImage"
                            />
                          </div>
                        </div>

                        <div class="form-group col-md-6">
                          <label for="inputRole"> Status</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputRole"
                            name="status"
                            v-model="detail.status"
                            rules="required:status"
                          >
                            <option value="" disabled>Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </Field>
                          <ErrorMessage name="status" class="text-danger" />
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TextModal ref="textModal" width="100">
          <button
            type="button"
            class="close"
            v-on:click="$refs.textModal.closeModal"
            style="margin-bottom: 106px; margin-top: -21px; margin-right: 4px"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div>
            <video
              v-if="detail.title"
              width="400"
              style="
                margin-left: 37px;
                margin-right: 40px;
                margin-top: 36px;
                margin-bottom: 60px;
              "
              controls
            >
              <source :src="detail.title" type="video/mp4" />
              Your browser does not support HTML video.
            </video>
            <h3 v-if="!detail.title" style="margin: 40px; margin-left: 115px">
              No Video Available
            </h3>
          </div>
        </TextModal>
      </div>
    </div>
  </div>
</template>
    <script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import DocumentPicker from "@/components/DocumentPicker.vue";

export default {
  name: "AddOnboarding",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    DocumentPicker,
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      fileName: "",
      option: "",
      file: undefined,
      detail: {
        value: "",
        list: [],
      },
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    if (this.uuid) {
      this.getDetail();
    }
  },
  methods: {
    saveOption() {
      var obj = {
        title: this.option,
      };
      this.detail.list.push(obj);
      this.option = "";
    },
    removeOption(index) {
      this.detail.list.splice(index, 1);
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/question/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
          this.detail.list = JSON.parse(res.info.value);
        })
        .catch(() => {});
    },
    save() {
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "/question/" + this.uuid,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            console.log(res);

            if (this.detail.type == "VIDEO" && this.file) {
              this.uploadImage(res.id, this.file, res.message);
            } else if (
              this.detail.type == "PDF" &&
              this.$refs.documentImage.file
            ) {
              this.uploadImage(res.id, this.$refs.documentImage.file, res.message);
            } else {
            
              this.$router.go(-1);
            }
            // this.$notify({
            //   type: "success",
            //   text: res.message,
            // });
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/question",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            console.log(res);
            if (this.detail.type == "VIDEO" && this.file) {
              this.uploadImage(res.id, this.file, res.message);
            } else if (
              this.detail.type == "PDF" &&
              this.$refs.documentImage.file
            ) {
              this.uploadImage(res.id, this.$refs.documentImage.file, res.message);
            } else {
              
              this.$router.go(-1);
            }
            // this.$notify({
            //   type: "success",
            //   text: res.message,
            // });
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "video/mp4" &&
        files[0].type.toLowerCase() != "video/webm" &&
        files[0].type.toLowerCase() != "video/quicktime"
      ) {
        alert("Invalid file formate, please use video or pdf file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      // var size = e.target.files[0].size / 1024 / 1024
      if (size > 500) {
        this.$notify({
          type: "error",
          text: "File must be less then 500 MB",
        });
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      this.fileName = files[0].name;
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(id, file, message) {
      console.log(message);
      if (this.file || this.$refs.documentImage.file) {
        this.$api
          .uploadImageAPI({
            _action: "/question/uploadfile/" + id,
            _key: "file",
            _file: file,
            _buttonId: "save-button",
            _body: {
              id: id,
            },
          })
          .then(() => {
            this.$notify({
              type: "success",
              text: message,
            });
            this.$router.go(-1);
          });
      } else {
        this.$router.go(-1);
      }
    },
    showPopUp() {
      this.$refs.textModal.showModal();
    },
  },
};
</script>