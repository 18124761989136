<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Tools</h4>
          </div>
        </div>
        <div>
          <ul
            class="nav nav-pills col-12 row p-0 m-0"
            id="myTab"
            role="tablist"
          >
            <li class="nav-item cursor-pointer mb-3 col-6 p-0 m-0">
              <a
                class="nav-link"
                data-toggle="tab"
                style="
                  text-align: center;
                  align-content: end;
                  background-color: white;
                "
                v-on:click="
                  $router.push('/edit-subscription-plan/' + this.uuid)
                "
              >
                <span> Basic Information</span></a
              >
            </li>
            <li class="nav-item cursor-pointer col-6 p-0 m-0">
              <a
                class="nav-link active"
                data-toggle="tab"
                style="text-align: center; align-content: start"
                :class="{ active: currentTab == 'TOOLS' }"
                v-on:click="changeTab('TOOLS')"
              >
                <span>Tools</span></a
              >
            </li>
          </ul>
        </div>
        <div
          class="page-content"
          v-show="accessObject?.is_view == 1 || !isShowAccessDeniedMessage"
        >
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 11px"
                    aria-hidden="true"
                  ></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword"
                  />
                </div>
                <a
                  class="fill-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
              </div>
              <!-- <div
                  v-show="accessObject?.is_add == 1"
                  style="cursor: pointer"
                  class="col-lg-6 col-sm-6 txt-right form-group"
                >
                  <a
                    class="fill-btn cursor-pointer"
                    style="padding: 11px; margin-right: 16px"
                    v-on:click="$router.push('/add-admin')"
                    >+ Add</a
                  >
                </div> -->
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr role="row">
                          <th
                            class="sorting"
                            id="id-title"
                            v-on:click="sorting('title', 'id-title')"
                          >
                            Title
                          </th>
                          <th
                            class="sorting"
                            id="id-status"
                            v-on:click="sorting('status', 'id-status')"
                          >
                            Status
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{ data.title ? data.title : "-" }}
                          </td>
                          <td style="text-align: left">
                            {{
                              data.status
                                ? this.$helperService.getTitleCase(data.status)
                                : "-"
                            }}
                          </td>
                          <td>
                            <div
                              class="custom-control custom-switch d-inline mr-2"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                v-model="data.is_ticked"
                                true-value="1"
                                false-value="0"
                                :id="'status_' + data.id"
                                @change="checkStatus()"
                              />
                              <label
                                class="custom-control-label"
                                :for="'status_' + data.id"
                              ></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination"
                      />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage"
        >
          <h3>You do not have access to view admin</h3>
        </div>
      </div>
    </div>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
    <script>
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
import Pagination from "@/components/Pagination";

export default {
  name: "Tools",
  components: {
    ErrorComponent,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("Manage Admins"),
      currentTab: "",
      uuid: this.$route.params.uuid,
      loader: true,
      isShowAccessDeniedMessage: false,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
    };
  },
  mounted() {
    // if (!this.accessObject) {
    //   this.isShowAccessDeniedMessage = true;
    //   this.$router.push("/access-denied");
    //   return;
    // }
    this.changeTab("TOOLS");
    this.$helperService.showMenu("true");
    this.getList(0);
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    checkStatus() {
      var obj = {};
      obj.list = [];
      for (var i in this.list) {
        if (this.list[i].is_ticked) {
          obj.list.push(this.list[i].id);
        }
      }
      this.$api
        .postAPI({
          _action: "/tool/subscription/" + this.uuid,
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.status,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/tools/" + this.uuid,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No data available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Admin",
        "Are you sure you want to delete " + data.first_name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/delete/" + detail.uuid,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>