<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
            <a class="pr-2 text-dark cursor-pointer" v-on:click="$router.push('/lms')"><span class="font-24 mr-2"><img
                  src="/images/back-arrow.svg" width="18" /></span>
            </a>
            <h4 class="mb-0 lg-bld">Manage Lesson</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i class="fa fa-search" style="position: absolute; margin: 11px" aria-hidden="true"></i>
                  <input type="text" class="form-control" v-on:keyup.enter="getList(0)" placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <a class="fill-btn btn-style cursor-pointer ml-3" v-on:click="getList(0)">Search</a>
                <a class="fill-btn btn-style cursor-pointer" v-on:click="resetFilter" v-show="searchObj.keyword">Reset</a>
              </div>
              <div style="cursor: pointer" class="col-lg-6 col-sm-6 txt-right form-group">
                <a class="fill-btn cursor-pointer" style="padding: 11px; margin-right: 16px"
                  v-on:click="$router.push('/add-lms-lesson/' + this.id)">+ Add</a>
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table class="table table-striped table-bordered dataTable p-0 m-0" style="width: 100%" role="grid"
                      aria-describedby="example_info">
                      <thead>
                        <tr role="row">
                          <th style="width: 10%">&nbsp;</th>
                          <th class="sorting" id="id-title" v-on:click="sorting('title', 'id-title')" style="width: 30%">
                            Lesson <span class="fr"> </span>
                          </th>
                          <th class="sorting" id="id-type" v-on:click="sorting('type', 'id-type')" style="width: 20%">
                            Type<span class="fr"> </span>
                          </th>
                          <th class="sorting" id="id-status" v-on:click="sorting('status', 'id-status')"
                            style="width: 20%">
                            Status<span class="fr"> </span>
                          </th>
                          <th style="width: 20%">
                            Actions<span class="fr"> </span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <draggable class="w-full" :list="list" @change="changeOrder">
                      <div class="d-flex" v-for="(data, index) in list" :key="index" style="
                          border: 1px solid lightgray;
                          border-top-width: 0px;
                        " :style="index % 2 == 0
                          ? 'background-color:#f2f2f2'
                          : 'background-color:#FFFFF'
                          ">
                        <div style="border-right: 1px solid lightgray; width: 10%">
                          <i class="fa fa-bars ml-4 mt-3" aria-hidden="true"></i>
                        </div>
                        <div style="border-right: 1px solid lightgray; width: 30%" class="p-2">
                          {{
                            data.title
                            ? $helperService.getText(data.title, 35)
                            : "-"
                          }}
                        </div>
                        <div style="border-right: 1px solid lightgray; width: 20%" class="p-3">
                          {{
                            data.type
                            ? $helperService.getTitleCase(data.type)
                            : "-"
                          }}
                        </div>
                        <div style="border-right: 1px solid lightgray; width: 20%" class="p-3">
                          {{
                            data.status
                            ? $helperService.getTitleCase(data.status)
                            : "-"
                          }}
                        </div>
                        <div class="p-3" style="border-right: 1px solid lightgray; width: 20%">

                          <a class="fill-btn btn-style cursor-pointer" v-on:click="
                          $router.push(
                            '/view-question/' + data.id
                          )
                            ">View Question</a>

                          <a class="fill-btn btn-style cursor-pointer" v-on:click="
                            $router.push(
                              '/edit-lms-lesson/' + data.uuid + '/' + data.id
                            )
                            ">Edit</a>
                          <a class="fill-btn btn-style cursor-pointer" v-on:click="showDeletePopup(data)">Delete</a>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination @page-change="pageChange" @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
                <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import { VueDraggableNext } from "vue-draggable-next";
import swal from "sweetalert2";

export default {
  name: "ViewLesson",
  components: {
    ErrorComponent,
    Pagination,
    draggable: VueDraggableNext,
  },
  data() {
    return {
      id: this.$route.params.id,
      uuid: this.$route.params.uuid,
      accessObject: this.$helperService.getAccessLevel("Manage Admins"),
      loader: true,
      isShowAccessDeniedMessage: false,
      searchObj: {
        keyword: "",
      },
      offset: -1,
      list: [],
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    this.getList(0);
  },
  methods: {
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.$api
        .getAPI({
          _action: "/lessons/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          this.accessObject =
            this.$helperService.getAccessLevel("Manage Admins");
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No data available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    changeOrder(event) {
      var new_index = event.moved.newIndex;
      var obj = {};
      obj.uuid = event.moved.element.uuid;
      obj.ordering = new_index <= 0 ? 1 : new_index;
      obj.list = this.list;
      this.$api
        .postAPI({
          _action: "/lesson/update-ordering",
          _body: obj,
        })
        .then(() => {
          this.getList(0);
        })
        .catch(() => { });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },

    showDeletePopup(data) {
      swal
        .fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete " + data.title,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$api
              .deleteAPI({
                _action: "/lesson/" + data.uuid,
              })
              .then((res) => {
                if (res) {
                  this.getList(0);
                  this.$notify({
                    type: "success",
                    text: res.message,
                  });
                }
              })
              .catch(() => {
                this.$refs.deleteModal.removeLoader();
              });
          }
        });
    },
  },
};
</script>