var name = "Test message";

class Global {

    setName(name) {
        this.name = name;
    }

    getName() {
        return name;
    }
}

export default new Global({})