<template>
  <div>
    <div class="sidebar-wrapper" style="overflow-y: scroll" id="sidebar-div">
      <div class="sidebar-header">
        <div class="">
          <img src="/images/logo-dark.svg" width="80" />
          <!-- <i  class="bx bx-menu"></i> -->
        </div>
      </div>
      <ul class="metismenu" id="menu">
        <!-- <li v-show="$helperService.getLeftMenuAccess('Dashboard')">
          <a id="dashboard" :class="{ active: pageName == 'dashboard' }" style="cursor: pointer"
            v-on:click="$router.push('/dashboard')">
            <img src="/img/dashboard.png" />
             <span class="menu-title" style="font-size: 12px;">Dashboard</span>
          </a>
        </li> -->
        <li v-for="(data, index) in list" :key="index">
          <a
            :id="'landingPage_' + index"
            :class="{ active: pageName.includes(data.url.replaceAll('/', '')) }"
            style="cursor: pointer"
            v-on:click="$router.push(data.url)"
          >
            <!-- <i class="fa fa-user" aria-hidden="true" style="display: flex;"></i> -->
            <img
              :src="'/images/' + data.icon_class"
              style="height: 20px; width: 20px"
            />
            <span class="menu-title" style="font-size: 12px">{{
              $helperService.getTitleCase(data.name.replaceAll("menu.", ""))
            }}</span>
          </a>
        </li>
      </ul>
    </div>
    <header class="top-header">
      <nav class="navbar navbar-expand">
        <div class="left-topbar d-flex align-items-center">
          <a href="javascript:;" class="toggle-btn">
            <i class="bx bx-menu"></i>
          </a>
        </div>
        <div class="right-topbar ml-auto">
          <ul class="navbar-nav">
            <li class="nav-item dropdown dropdown-user-profile">
              <a
                class="nav-link dropdown-toggle dropdown-toggle-nocaret pr-0"
                href="javascript:;"
                data-toggle="dropdown"
              >
                <div class="media user-box align-items-center">
                  <div class="media-body user-info">
                    <p
                      class="user-name mb-0"
                      id="loginuser-name"
                      style="color: white; font-size: 15px"
                    >
                      {{ details.first_name }} {{ details.last_name }}
                    </p>
                  </div>
                  <img
                    :src="details.image ? details.image : '/img/user-pic.png'"
                    class="user-img"
                    alt="user avatar"
                    id="loginuser-image"
                  />
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link to="/my-profile">
                  <a class="dropdown-item">
                    <i class="bx bx-user"></i>
                    <span>Profile</span>
                  </a>
                </router-link>
                <div class="dropdown-divider mb-0"></div>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  v-on:click="logout"
                >
                  <i class="bx bx-power-off"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>
<script>
import api from "../services/WebService";
import swal from "sweetalert2";
export default {
  name: "Menu",
  data() {
    return {
      list: [],
      pageName: "",
      details: {},
      isShow: false,
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      var pathname = newValue.replace("/", "");
      this.pageName = pathname;
    },
  },
  mounted() {
    this.getList();
    this.pageName = this.$route.fullPath.replace("/", "");
    this.getLoginUserDetail();
  },
  methods: {
    isShowList() {
      this.isShow = !this.isShow;
    },
    getList() {
      this.list = [];
      this.$api
        .getAPI({
          _action: "/left-menus",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.$helperService.setAccessLevel(res.list);
        })
        .catch(() => {});
    },

    // logout() {
    //   api
    //     .postAPI({
    //       _action: "/unregister/device/token",
    //     })
    //     .then(() => {
    //       localStorage.clear();
    //       this.$router.push("/login");
    //     })
    //     .catch(() => {});
    // },
    logout() {
      swal
        .fire({
          title: "Are you sure?",
          text: "You will be logged out of the admin portal",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Logout",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            localStorage.clear();
            this.$router.push("/login");
          }
        });
    },
    getLoginUserDetail() {
      api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          // if (res.role != "ADMIN") {
          //   this.$notify({
          //     type: "success",
          //     text: "You don't have access ",
          //   });
          // }

          // if (window.location.pathname == "/access-denied") {
          //   this.$router.go(-1);
          // }
          // if (res.access && res.access.length > 0) {
          //   console.log("access", res.)
          //   this.$helperService.setAccessLevel(res.access);
          // }
          this.details = res.info;
        })
        .catch(() => {});
    },
  },
};
const accSingleTriggers = document.querySelectorAll(".js-acc-single-trigger");
accSingleTriggers.forEach((trigger) =>
  trigger.addEventListener("click", toggleAccordion)
);

function toggleAccordion() {
  const items = document.querySelectorAll(".js-acc-item");
  const thisItem = this.parentNode;
  items.forEach((item) => {
    if (thisItem == item) {
      thisItem.classList.toggle("is-open");
      return;
    }
    item.classList.remove("is-open");
  });
}
</script>