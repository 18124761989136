<template>
  <div>
    <div class="login-bg" style="background: rgb(245, 91, 176)">
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-lg-5 col-sm-6 fl-none">
            <div class="posAbs">
              <router-link to="/forgot-password">
                <a style="color: #000"><i class="fa fa-arrow-left"></i></a>
              </router-link>
            </div>
            <!-- <div class="text-center">
              <img src="/static/img/mid-logo.png" alt="" />
            </div> -->
            <div class="forgot-ps">OTP Verification</div>
            <div class="text-center">
              <img src="/img/OTP.png" alt="" width="86" />
            </div>
            <div class="enter-your">Please enter verification code.</div>
            <Form @submit="verifyOtp">
              <div class="text-center mb-4 digit-group">
                <Field
                  id="codeBox1"
                  type="text"
                  name="otp1"
                  class="otp-control"
                  maxlength="1"
                  rules="required"
                  v-on:keyup="onKeyUpEvent(1, $event)"
                  v-on:focus="onFocusEvent(1)"
                />
                <Field
                  id="codeBox2"
                  type="text"
                  name="otp2"
                  maxlength="1"
                  rules="required"
                  v-on:keyup="onKeyUpEvent(2, $event)"
                  v-on:focus="onFocusEvent(2)"
                />
                <Field
                  id="codeBox3"
                  type="text"
                  name="otp3"
                  maxlength="1"
                  rules="required"
                  v-on:keyup="onKeyUpEvent(3, $event)"
                  v-on:focus="onFocusEvent(3)"
                />
                <Field
                  id="codeBox4"
                  type="text"
                  name="otp4"
                  class="otp-control"
                  maxlength="1"
                  rules="required:otp"
                  v-on:keyup="onKeyUpEvent(4, $event)"
                  v-on:focus="onFocusEvent(4)"
                />
              </div>
              <div class="text-center">
                <ErrorMessage name="otp4" class="text-danger" />
              </div>
              <div class="verification mb-4" style="margin-top: 16px">
                We will send verification code on your registerd email
              </div>
              <div class="text-center mb-4">
                <button class="send-btn" id="verify-otp-button">Verify</button>
              </div>
              <div class="verification">
                Didn’t Get the code?
                <a class="font-weight-bold"
                  ><a
                    style="cursor: pointer"
                    id="resend-button"
                    v-on:click="resend()"
                    >Resend</a
                  ></a
                >.
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "OTP",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    verifyOtp() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.otp =
        document.getElementById("codeBox1").value +
        "" +
        document.getElementById("codeBox2").value +
        "" +
        document.getElementById("codeBox3").value +
        "" +
        document.getElementById("codeBox4").value;
      this.$api
        .putAPI({
          _action: "/verify/otp",
          _body: obj,
          _buttonId: "verify-otp-button",
        })
        .then(() => {
          localStorage.setItem("token", obj.otp);
          this.$router.push("/reset-password");
        })
        .catch(() => {});
    },
    resend() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      this.$api
        .postAPI({
          _action: "/resend/otp",
          _body: obj,
          _buttonId: "resend-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>