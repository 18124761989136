<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/view-lms/' + this.detail.chapter_id)"><span class="font-24 mr-2"><img
                    src="/images/back-arrow.svg" width="18" /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} Lesson</h4>
            </div>
          </div>
        </div>
        <div>
          <ul class="nav nav-pills col-12 row p-0 m-0" id="myTab" role="tablist" v-if="uuid">
            <li class="nav-item cursor-pointer mb-3 col-6 p-0 m-0">
              <a class="nav-link active" data-toggle="tab" style="text-align: center; align-content: start"
                :class="{ active: currentTab == 'BASIC_INFO' }" v-on:click="changeTab('BASIC_INFO')">
                <span> Basic Info</span></a>
            </li>
            <li class="nav-item cursor-pointer col-6 p-0 m-0">
              <a class="nav-link" data-toggle="tab" style="
                  text-align: center;
                  align-content: end;
                  background-color: white;
                " v-on:click="
                  $router.push('/lms-checklist/' + this.uuid + '/' + this.id)
                  ">
                <span>CheckList</span></a>
            </li>
          </ul>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="lessonForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="title" class="form-control" placeholder="Title"
                              v-model="detail.title" rules="required:title" :validateOnInput="true" />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="inputType">Type</label>
                          <span class="text-danger">*</span>
                          <Field as="select" aria-describedat="" class="form-control" id="inputType" name="type"
                            v-model="detail.type" rules="required:type">
                            <option value="" disabled>Select Type</option>
                            <option value="DAILY_CHECKLIST">
                              Daily Checklist
                            </option>
                            <option value="INTROSPECTION_ACTIVITY">
                              Introspection Activity
                            </option>
                            <option value="PDF">PDF</option>
                            <option value="TEXT">Text</option>
                            <option value="VIDEO">Video</option>
                          </Field>
                          <ErrorMessage name="type" class="text-danger" />
                        </div>
                        <div class="col-md-7 d-md-flexs" v-if="detail.type == 'VIDEO'">
                          <label class="font-14 d-block font-bold text-dark">Video</label>
                          <div class="text-left">
                            <a v-if="detail.url" class="fill-btn btn-style cursor-pointer"
                              v-on:click="showPopUp()">Watch</a>
                          </div>
                          <div>
                            <input type="file" name="img[]" class="file" id="video" style="display: none"
                              @change="onChange($event)" />
                            <label for="video" style="margin-top: 20px"><a v-if="!detail.url" class="browse">Browse...
                                &nbsp; &nbsp;</a>
                              <a style="
                                  float: right;
                                  cursor: pointer;
                                  text-decoration: underline;
                                " v-if="detail.url" class="">Change</a>

                              <a>
                                {{
                                  detail.url || videoFileName
                                  ? videoFileName
                                  : "No File Selected"
                                }}</a>
                            </label>
                          </div>

                          <!-- <VideoPicker :src="detail.url" ref="videoPicker" /> -->
                        </div>
                        <div class="col-lg-5 col-sm-5" v-if="detail.type == 'VIDEO'" style="margin-left: -61px">
                          <label for="inputType">Thumbnail</label>

                          <div class="text-left">
                            <!-- <a
                              v-if="detail.title"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showPopUp()"
                              >Watch</a
                            > -->
                            <img style="height: 77px; width: 77px" :src="detail.thumbnail
                              ? detail.thumbnail
                              : '/images/placeholder.gif'
                              " class="img-fit-cover rounded-5" id="cover" />
                          </div>
                          <div>
                            <input type="file" name="img[]" class="file" id="preview" style="display: none"
                              @change="selectedFile($event)" />
                            <label for="preview"><a class="">Browse... &nbsp; &nbsp;</a>
                              <a>
                                {{
                                  detail.thumbnail || fileName
                                  ? fileName
                                  : "No File Selected"
                                }}</a>
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 mb-3 row" v-if="detail.type == 'PDF'">
                          <div class="col-md-6 mb-4">
                            <DocumentPicker :src="detail.url ? detail.url : ''" ref="documentImage" />
                          </div>
                        </div>

                        <!-- <div
                          class="col-lg-6 col-sm-6 row mb-4"
                          v-if="detail.type == 'DAILY_CHECKLIST'"
                        >
                          <div class="col-lg-11">
                            <label>Option</label>
                            <Field
                              type="text"
                              name="option"
                              class="form-control"
                              placeholder="Option"
                              v-model="option"
                            />
                          </div>
                          <div class="col-lg-1" style="top: 31px">
                            <i
                              v-on:click="saveOption()"
                              class="fa fa-plus cursor-pointer"
                              aria-hidden="true"
                              style="
                                font-size: 20px;
                                height: 30px;
                                padding: 6px;
                              "
                            ></i>
                          </div>
                        </div> -->

                        <!-- <div
                          class="table-responsive col-lg-12"
                          v-if="detail.type == 'DAILY_CHECKLIST'"
                        >
                          <table class="table">
                            <thead>
                              <tr role="row">
                                <th>Title</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in detail.list"
                                :key="index"
                              >
                                <td>
                                  <Field
                                    :id="'title_' + index"
                                    type="text"
                                    :name="'title_' + index"
                                    class="form-control"
                                    placeholder="Option"
                                    v-model="data.title"
                                  />
                                </td>
                                <td>
                                
                                  <a
                                    class="mr-2 text-danger cursor-pointer"
                                    v-on:click="removeOption(index)"
                                    ><i class="bx bx-trash"></i
                                  ></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> -->

                        <div class="col-lg-12 col-sm-12">
                          <div class="form-group">
                            <label>Content</label>
                            <span class="text-danger">*</span>
                            <Field as="textarea" name="content" class="form-control" placeholder="Content"
                              v-model="detail.content" rules="required:content" :validateOnInput="true" />
                            <ErrorMessage name="content" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="detail.type == 'VIDEO'">
                          <div class="form-group">
                            <label>Duration</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="duration" class="form-control" placeholder="Duration"
                              v-model="detail.duration" rules="required:duration" :validateOnInput="true" />
                            <ErrorMessage name="duration" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Stage Complete Percent</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="stage_complete_percent" class="form-control"
                              placeholder="Stage Complete Percent" v-model="detail.stage_complete_percent"
                              rules="required:stage complete percent" :validateOnInput="true" @keypress="
                                $helperService.allowOnlyNumericValue($event)
                                " />
                            <ErrorMessage name="stage_complete_percent" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Offer Reward</label>
                            <span class="text-danger">*</span>
                            <Field type="text" name="offered_reward" class="form-control" placeholder="Offered Reward"
                              v-model="detail.offered_reward" rules="required:offer reward" :validateOnInput="true"
                              @keypress="
                                $helperService.allowOnlyNumericValue($event)
                                " />
                            <ErrorMessage name="offered_reward" class="text-danger" />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="inputRole"> Status</label>
                          <span class="text-danger">*</span>
                          <Field as="select" aria-describedat="" class="form-control" id="inputRole" name="status"
                            v-model="detail.status" rules="required:status">
                            <option value="" disabled>Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </Field>
                          <ErrorMessage name="status" class="text-danger" />
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button type="button" class="fil-btn px-4 ml-3" v-on:click="$router.go(-1)" id="cancel-button">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TextModal ref="textModal" width="100">
          <button type="button" class="close" v-on:click="$refs.textModal.closeModal"
            style="margin-bottom: 106px; margin-top: -21px; margin-right: 4px">
            <span aria-hidden="true">&times;</span>
          </button>
          <div>
            <video v-if="detail.url" width="400" style="
                margin-left: 37px;
                margin-right: 40px;
                margin-top: 36px;
                margin-bottom: 60px;
              " controls>
              <source :src="detail.url" type="video/mp4" />
              Your browser does not support HTML video.
            </video>
            <h3 v-if="!detail.url" style="margin: 40px; margin-left: 115px">
              No Video Available
            </h3>
          </div>
        </TextModal>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
// import VideoPicker from "@/components/videoPicker.vue";
import TextModal from "@/components/TextModal";
import DocumentPicker from "@/components/DocumentPicker.vue";
export default {
  name: "AddLesson",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    // VideoPicker,
    DocumentPicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      uuid: this.$route.params.uuid,
      detail: {
        chapter_id: "",
        url: "",
        list: [],
      },
      fileName: "",
      videoFileName: "",
      file: undefined,
      videoFile: undefined,
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    // if (this.uuid) {
    //   localStorage.setItem("lesson_id", this.uuid);
    // } else if (localStorage.getItem("lesson_id")) {
    //   this.id = localStorage.getItem("lesson_id");
    // }
    if (this.uuid) {
      this.getDetail();
    }
  },
  methods: {
    // saveOption() {
    //   var obj = {
    //     title: this.option,
    //   };
    //   this.detail.list.push(obj);
    //   this.option = "";
    // },
    // removeOption(index) {
    //   this.detail.list.splice(index, 1);
    // },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/lesson/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
          this.detail.url = res.info.url;
          this.detail.list = JSON.parse(res.info.value);
        })
        .catch(() => { });
    },
    save() {
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "/lesson/" + this.uuid,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.detail.type == "VIDEO") {
              if (this.videoFile) {
                this.uploadImage(res.id, this.videoFile, res.message);
              } else {
                if (this.file) {
                  this.uploadThumbnail(res.id, this.file, res.message);
                } else {

                  this.$notify({
                    type: "success",
                    text: res.message,
                  });
                  this.$router.go(-1);
                }
              }
            }
            else if (this.$refs.documentImage.file) {
              this.uploadImage(res.id, this.$refs.documentImage.file, res.message);
            } else if (this.detail.type == "DAILY_CHECKLIST") {
              this.$router.push("/lms-checklist/" + this.uuid + "/" + this.id);
            } else {
              this.$router.push('/view-lms/' + this.detail.chapter_id);

            }
          })
          .catch(() => {
          });
      } else {
        if (this.id) {
          this.detail.chapter_id = this.id;
        }
        this.$api
          .postAPI({
            _action: "/lesson",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.detail.type == "VIDEO") {
              if (this.videoFile) {
                this.uploadImage(res.id, this.videoFile, res.message);
              } else {
                if (this.file) {
                  this.uploadThumbnail(res.id, this.file, res.message);
                } else {
                  this.$notify({
                    type: "success",
                    text: res.message,
                  });
                  this.$router.go(-1);
                }
              }
            } else if (
              this.detail.type == "PDF" &&
              this.$refs.documentImage.file
            ) {
              this.uploadImage(res.id, this.$refs.documentImage.file, res.message);
            } else if (this.detail.type == "DAILY_CHECKLIST") {
              this.$router.push("/lms-checklist/" + res.id + "/" + this.id);
            } else {
              this.$router.push('/view-lms/' + this.detail.chapter_id);
            }
          })
          .catch(() => {
          });
      }
    },

    uploadImage(id, file, message) {
      if (file) {
        this.$api
          .uploadImageAPI({
            _action: "/lesson/uploadfile/" + id,
            _key: "file",
            _file: file,
            _buttonId: "save-button",
            _body: {
              id: id,
            },
          })
          .then(() => {
            this.$notify({
              type: "success",
              text: message,
            });
            if (this.file) {
              this.uploadThumbnail(id, this.file, message);
            } else {
              this.$router.go(-1);
            }
          });
      } else {
        if (this.file) {
          this.uploadThumbnail(id, this.file, message);
        } else {
          this.$router.go(-1);
        }
      }
    },
    uploadThumbnail(id, file, message) {
      if (file) {
        this.$api
          .uploadImageAPI({
            _action: "/lesson/thumb/" + id,
            _key: "file",
            _file: file,
            _buttonId: "save-button",
            _body: {
              id: id,
            },
          })
          .then(() => {
            this.$notify({
              type: "success",
              text: message,
            });
            this.$router.go(-1);
          });
      } else {
        this.$router.go(-1);
      }
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "video/mp4" &&
        files[0].type.toLowerCase() != "video/webm" &&
        files[0].type.toLowerCase() != "video/quicktime"
      ) {
        alert("Invalid file formate, please use mp4 or webm mov file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      // var size = e.target.files[0].size / 1024 / 1024
      if (size > 500) {
        this.$notify({
          type: "error",
          text: "File must be less then 500 MB",
        });
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.videoFile = files[0];
      this.videoFileName = files[0].name;
      document.getElementById("video").src = tmppath;
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/png" &&
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/svg+xml"
      ) {
        alert("Invalid file formate, please use jpg or svg file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      // var size = e.target.files[0].size / 1024 / 1024
      if (size > 10) {
        this.$notify({
          type: "error",
          text: "File must be less then 10 MB",
        });
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      console.log("type", files[0].type);
      this.fileName = files[0].name;
      document.getElementById("preview").src = tmppath;
    },
    showPopUp() {
      this.$refs.textModal.showModal();
    },
    // pdfUrl() {
    //   window.open(this.detail.url);
    // },
  },
};
</script>

