<template>
  <div class="wrapper">
    <Menu />
    <router-view></router-view>
  </div>
</template>

<script>
    import Menu from "@/components/menu";
    export default {
        name: "Main",
        components: {
            Menu,
        },
        methods: {
            callMethod() {
                Menu.methods.getLoginUserDetail()
            }
        }
    };
</script>