<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="this.$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} CheckList</h4>
            </div>
          </div>
        </div>

        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="lessonForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-12 col-sm-12">
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="title"
                              class="form-control"
                              placeholder="Title"
                              v-model="detail.content"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddCheckList",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      uuid: this.$route.params.uuid,
      detail: {
        lesson_id: "",
      },
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    // if (this.uuid) {
    //   localStorage.setItem("lesson_id", this.uuid);
    // } else if (localStorage.getItem("lesson_id")) {
    //   this.id = localStorage.getItem("lesson_id");
    // }
    if (this.uuid) {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      if (this.detail.lesson_id) {
        this.detail.lesson_id = this.id;
      }
      this.$api
        .getAPI({
          _action: "/checklist/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "/checklist/" + this.uuid,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        if (this.id) {
          this.detail.lesson_id = this.id;
        }
        this.$api
          .postAPI({
            _action: "/checklist",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },

    // selectedFile(e) {
    //   let files = e.target.files;
    //   if (
    //     files[0].type.toLowerCase() != "image/jpeg" &&
    //     files[0].type.toLowerCase() != "image/png"
    //   ) {
    //     alert("Invalid file formate, please use jpeg or png file.");
    //     return;
    //   }
    //   const size = (files[0].size / 1024 / 1024).toFixed(2);
    //   // var size = e.target.files[0].size / 1024 / 1024
    //   if (size > 1) {
    //     this.$notify({
    //       type: "error",
    //       text: "File must be less then 1 MB",
    //     });
    //     return;
    //   }
    //   var tmppath = URL.createObjectURL(files[0]);
    //   this.file = files[0];
    //   document.getElementById("preview").src = tmppath;
    // },
  },
};
</script>