<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6S">
            <h4 class="mb-0 lg-bld">Dashboard</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-3 form-group">
                <Field
                  type="text"
                  name="from_date"
                  placeholder="From Date"
                  v-model="from_date"
                  v-slot="{ field }"
                >
                  <Datepicker
                    autoApply
                    v-model="from_date"
                    inputFormat="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    @closed="getUser()"
                    icon="true"
                    placeholder="From Date"
                    :max-date="to_date"
                    :enableTimePicker="false"
                    v-bind="field"
                    @change="getUser()"
                  />
                </Field>
              </div>
              <div class="col-lg-3 form-group">
                <Field
                  type="text"
                  name="to_date"
                  placeholder="To Date"
                  v-model="to_date"
                  v-slot="{ field }"
                >
                  <Datepicker
                    autoApply
                    v-model="to_date"
                    inputFormat="yyyy-MM-dd"
                    @closed="getUser()"
                    format="yyyy-MM-dd"
                    icon="true"
                    placeholder="To Date"
                    :min-date="from_date"
                    :enableTimePicker="false"
                    v-bind="field"
                    @change="getUser()"
                  />
                </Field>
              </div>
              <div class="col-lg-10 ol-sm-10 float-none">
                <div class="card-body p-0">
                  <div class="row"></div>
                  <div class="card border mb-0 radius-15">
                    <div v-if="isCustomerLoader" class="loader text-center">
                      <img
                        src="/img/loader.gif"
                        alt="Loading.."
                        width="50"
                        height="50"
                      />
                    </div>
                    <div class="chartWrapper">
                      <div class="chartAreaWrapper">
                        <canvas
                          id="myChart1"
                          width="100%"
                          height="100%"
                          class="mt-3 ml-3"
                        >
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import { Field } from "vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";

// import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "Dashboard",
  components: {
    // ErrorComponent,
    Datepicker,
    Field,
  },
  data() {
    return {
      accessObject: this.$helperService.getAccessLevel("dashboard"),
      isShowAccessDeniedMessage: false,
      loader: false,
      isCustomerLoader: false,

      from_date: "",
      to_date: "",
      parameter: "",
      user_type: "",
      myChart: undefined,
      type: "",
      list: [],
      user: [],
      total_count: [],
      searchObj: {},
      detail: {},
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.isCustomerLoader = true;
      this.user = "";
      if (this.from_date) {
        this.searchObj.from_date = moment(this.from_date).format("YYYY-MM-DD");
      }
      if (this.to_date) {
        this.searchObj.to_date = moment(this.to_date).format("YYYY-MM-DD");
      }
      this.$api
        .getAPI({
          _action: "/dashboard",
          _body: this.searchObj,
        })
        .then((res) => {
          this.user = res.info;
          this.isCustomerLoader = false;
          this.getUserChart();
        })
        .catch(() => {
          this.isCustomerLoader = false;
        });
    },
    getUserChart() {
      var graph_data = [];
      var lable = [];
      if (this.user.length > 0) {
        for (var i in this.user) {
          graph_data.push(this.user[i].count);
          lable.push(this.user[i].key);
        }
      }
      if (this.myChart) {
        this.myChart.destroy();
      }
      const ctx = document.getElementById("myChart1").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: lable,
          datasets: [
            {
              label: "Date",
              data: graph_data,
              backgroundColor: "#2c0066",
              borderColor: "#2b539f",
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },

    showAddStatsPopup(type) {
      this.type = type;
      this.$refs.textModal.showModal();
      this.$refs.brandForm.resetForm();
    },
  },
};
</script>
<style>
.top-heading {
  font-size: 3rem !important;
}
</style>