<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/lms')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} Chapter</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="chapterForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="title"
                              class="form-control"
                              placeholder="Title"
                              v-model="detail.title"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>
                        <!-- <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                              <label>Order</label>
                              <span class="text-danger">*</span>
                              <Field
                                type="text"
                                name="ordering"
                                class="form-control"
                                placeholder="Ordering"
                                v-model="detail.ordering"
                                rules="required:ordering"
                                :validateOnInput="true"
                              />
                              <ErrorMessage name="ordering" class="text-danger" />
                            </div>
                          </div> -->

                        <div class="form-group col-md-6">
                          <label for="inputRole">Stage</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputStage"
                            name="stage"
                            v-model="detail.stage_id"
                            rules="required:stage"
                          >
                            <option value="" disabled>Select Stage</option>
                            <option
                              v-for="(data, index) in list"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.title }}
                            </option>
                          </Field>
                          <ErrorMessage name="status" class="text-danger" />
                        </div>

                        <div class="form-group col-md-6">
                          <label for="inputRole"> Status</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputRole"
                            name="status"
                            v-model="detail.status"
                            rules="required:status"
                          >
                            <option value="" disabled>Select Status</option>
                            <option value="PENDING">Draft</option>
                            <option value="ACTIVE">Publish</option>
                            <option value="INACTIVE">Unpublish</option>
                          </Field>
                          <ErrorMessage name="status" class="text-danger" />
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  name: "AddChapter",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      list: [],
      detail: {
        stage_id: "",
      },
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    this.getList();
    if (this.uuid) {
      this.getDetail();
    }
  },
  methods: {
    getList() {
      this.$api
        .getAPI({
          _action: "/stages",
        })
        .then((res) => {
          this.list = res.list;
          if (this.detail.stage_id != null) {
            this.stage_id = this.detail.stage_id;
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/chapter/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "/chapter/" + this.uuid,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/chapter",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
  },
};
</script>