<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/subscription-plan')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ uuid ? "Edit" : "Add" }} Subscription
              </h4>
            </div>
          </div>
        </div>
        <div>
          <ul
            class="nav nav-pills col-12 row p-0 m-0"
            id="myTab"
            role="tablist"
            v-if="uuid"
          >
            <li class="nav-item cursor-pointer mb-3 col-6 p-0 m-0">
              <a
                class="nav-link active"
                data-toggle="tab"
                style="text-align: center; align-content: start"
                :class="{ active: currentTab == 'BASIC_INFO' }"
                v-on:click="changeTab('BASIC_INFO')"
              >
                <span> Basic Information</span></a
              >
            </li>
            <li class="nav-item cursor-pointer col-6 p-0 m-0">
              <a
                class="nav-link"
                data-toggle="tab"
                style="
                  text-align: center;
                  align-content: end;
                  background-color: white;
                "
                v-on:click="
                  $router.push('/tools/' +this.uuid)
                "
              >
                <span>Tools</span></a
              >
            </li>
          </ul>
          </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Plan</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="plan"
                              class="form-control"
                              placeholder="Plan"
                              v-model="detail.title"
                              rules="required:plan"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="plan" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Amount</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="amount"
                              class="form-control"
                              placeholder="Amount"
                              v-model="detail.amount"
                              rules="required:amount"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="amount" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Duration</label>
                            <span class="text-danger">*</span>
                            <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            name="duration"
                            v-model="detail.type"
                            rules="required:duration"
                          >
                            <option value="" disabled>Select Parameter</option>
                            <option value="MONTHLY">Monthly</option>
                            <option value="YEARLY">Yearly</option>
                          </Field>
                            <ErrorMessage name="duration" class="text-danger" />
                          </div>
                        </div>
                        <!-- <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Stage</label>
                            <span class="text-danger">*</span>
                            <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            name="stage"
                            v-model="detail.stage_id"
                            rules="required:stage"
                          >
                            <option value="" disabled>Select Stage</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                          </Field>
                            <ErrorMessage name="stage" class="text-danger" />
                          </div>
                        </div> -->
                        <!-- <div class="col-md-6">
                          <label>Status</label>

                          <div class="custom-control custom-switch  ms-5">
                            <input
                              class="custom-control-input"
                              type="checkbox"
                              id="customSwitch1"
                              true-value="ACTIVE"
                              false-value="INACTIVE"
                              v-model="detail.status"
                            />
                            <label
                              class="custom-control-label"
                              for="customSwitch1"
                            >
                            </label>
                          </div>
                        </div> -->
                       
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddSubscription",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      detail: {},
      currentTab: "",
    };
  },
  mounted() {
    this.changeTab("BASIC_INFO");
    this.$helperService.showMenu('true')
    if (this.uuid) {
      this.getDetail();
    }
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/subscription/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "/subscription/" + this.uuid,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$router.go(-1);
             this.$notify({
              type: "success",
              text: res.message,
            });
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/subscription",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
             this.$notify({
              type: "success",
              text: "Subscription plan added successfully",
            });
          })
          .catch(() => {});
      }
    },
  
    
  },
};
</script>