<template>
  <a
    v-show="url"
    v-on:click="removeDocument()"
    class="remove-img"
    style="
      cursor: pointer;
      position: relative;
      margin-left: 532px;
      z-index: 9;
      top: 24px;
    "
    ><img
      src="/img/close-outline.svg"
      width="16"
      style="margin-left: -60px; margin-top: 500px justfy-content:right "
  /></a>
  <label
    :for="'input_' + compoentId"
    class="
      card
      mb-3
      shadow-sm
      card-hover
      live-event-card
      text-center
      border-primary
      h-90
      justify-content-center
      align-items-center
    "
    style="flex-direction: inherit; min-height: 300px; width: 500px; top: 24px"
  >
    <img
      v-if="url && !url.includes('.pdf') && file_type != 'application/pdf'"
      :src="url"
      :id="compoentId"
      class="thumbnail-img"
      height="300"
      width="500"
    />
    <span
      style="
        display: inline-block;
        font-size: 80%;
        line-height: normal;
        width: 200%;
        line-height: 2rem;
        border-radius: 25px;
        cursor: pointer;
      "
      v-if="(url && url.includes('.pdf')) || file_type == 'application/pdf'"
      ><img
        src="/img/pdf.png"
        :id="compoentId"
        class="thumbnail-img"
        height="50"
        width="50"
      />
      <div>
        {{ documentName }}
       
      </div>
      <div style="display: block ruby;margin: 10px;">
          <button type="button" class="btn btn-brand-1 px-5 mb-3 btn-sm" v-on:click="videoUrl(url)">
        View Pdf
      </button>
      </div>
      <!-- {{ url.replaceAll("https://marriagebeuroapi.winayak.com/users/", "") }} -->
    
    </span>
     
    <input
      type="file"
      name="img[]"
      class="file3"
      :id="'input_' + compoentId"
      @change="onChange($event)"
      style="display: none"
    />

    <a v-show="!url" class="font-18">
      <label :for="'input_' + compoentId">
        <div class="drag-text text-center" v-if="!back_image">
          <h3 class="font-20 font-regular text-brand">
            <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload
            Document
          </h3>

          <!-- <p class="font-12 text-secondary mb-1">PNG, GIF,
                                          JPEG.
                                          (Max 10 mb)</p> -->
        </div>
      </label></a
    >
  </label>
</template>
<script>
export default {
  name: "VideoPicker",
  props: {
    id: {
      type: String,
      default: "spx_img_1",
    },
    src: {
      type: String,
      default: "",
    },
    uuid: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    file_name: {
      type: String,
      default: "",
    },
  },
  mounted() {
    if (this.compoentId == "spx_img_1") {
      this.compoentId = "img_" + Math.floor(Math.random() * 1000);
    }
  },
  computed: {
    url() {
      return this.selectFile ? this.selectFile : this.src;
    },
    documentName() {
      return this.name ? this.name : this.file_name;
    },
  },
  data() {
    return {
      compoentId: this.id,
      selectFile: "",
      file_type: "",
      name: "",
      file: undefined,
      obj: {},
    };
  },
  methods: {
    videoUrl() {
      window.open(this.selectFile ? this.selectFile : this.src);
    },
    onChange(e) {
      let files = e.target.files;
      this.file_type = files[0].type;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png" &&
        files[0].type.toLowerCase() != "application/pdf"
      ) {
        alert("Invalid file formate, please use jpeg , png or pdf file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      if (size > 10) {
        alert("File must be less then 10 MB");
        return;
      }
      this.selectFile = URL.createObjectURL(files[0]);
      this.file = files[0];
      this.name = this.file.name;
      console.log("object", this.file);
    },
    onUploadImage() {
      this.$emit("image", this.obj);
    },
    removeDocument() {
      this.file_type = {};
      this.selectFile = "";
      this.file = {};
      this.$emit("removeDocument", { uuid: this.uuid, type: this.type });
    },
  },
};
</script>