import {
    createWebHistory,
    createRouter
} from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import OTP from "@/views/Onboarding/otp.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import MyProfile from "@/views/Onboarding/my-profile.vue";
import Dashboard from "@/views/Dashboard/dashboard.vue";
import Admin from "@/views/Admin/admin.vue";
import AddAdmin from "@/views/Admin/add-admin.vue";
import LMS from "@/views/LMS/lms.vue";
import AddChapter from "@/views/LMS/add-chapter.vue";
import SubscriptionPlan from "@/views/SubscriptionPlan/subscription-plan.vue";
import AddSubscription from "@/views/SubscriptionPlan/add-subscription-plan.vue";
import Tools from "@/views/Tools/tools.vue";
import ViewLesson from "@/views/LMS/lesson.vue";
import AddLesson from "@/views/LMS/add-lesson.vue";
import ViewQuestion from "@/views/LMS/question.vue";
import AddQuestion from "@/views/LMS/addQuestion.vue";
import CheckList from "@/views/LMS/checklist.vue";
import AddCheckList from "@/views/LMS/add-checklist.vue";
import Affirmation from "@/views/Affirmation/affirmation.vue";
import Users from "@/views/ManageUser/user.vue";
import AddUser from "@/views/ManageUser/add-user.vue";
import Stages from "@/views/Stages/stages.vue";
import Podcast from "@/views/Podcast/podcast.vue";
import AddPodcast from "@/views/Podcast/add-podcast.vue";
import Meditation from "@/views/Meditation/meditation.vue";
import AddMeditation from "@/views/Meditation/add-meditation.vue";
import AddAffirmation from "@/views/Affirmation/add-affirmation.vue";
import Onboarding from "@/views/Onboarding/onboarding.vue";
import AddOnboarding from "@/views/Onboarding/add-onboarding.vue";
import Role from "@/views/Role/role.vue";
import AddRole from "@/views/Role/add-role.vue";
import Menu from "@/components/menu.vue";
import MainView from "@/views/main.vue";
import AccessDenied from "@/views/Error/access-denied.vue"

const routes = [{
        path: "",
        redirect: Login,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/otp",
        name: "OTP",
        component: OTP,
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: '',
        name: "Main",
        component: MainView,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: "/access-denied",
                name: "AccessDenied",
                component: AccessDenied,
            },

            // =============== Admin ===============
            {
                path: "/admin",
                name: "Admin",
                component: Admin
            },
            {
                path: "/add-admin",
                name: "Add Admin",
                component: AddAdmin
            },
            {
                path: "/edit-admin/:uuid",
                name: "Edit Admin",
                component: AddAdmin
            },
            //================Dashboard================
            {
                path: "/dashboard",
                name: "Dashboard",
                component: Dashboard
            },
            //===========LMS==========
            {
                path: "/lms",
                name: "Lms",
                component: LMS
            },
            {
                path: "/add-lms",
                name: "Add Chapter",
                component: AddChapter
            },
            {
                path: "/edit-lms/:uuid",
                name: "Edit Chapter",
                component: AddChapter
            },
            {
                path: "/add-lms-lesson/:id",
                name: "Add Lesson",
                component: AddLesson
            },
            {
                path: "/edit-lms-lesson/:uuid/:id",
                name: "Edit Lesson",
                component: AddLesson
            },
            {
                path: "/view-lms/:id",
                name: "View Lesson",
                component: ViewLesson
            },
            {
                path: "/add-question/:id",
                name: "Add Question",
                component: AddQuestion
            },
            {
                path: "/edit-question/:id/:uuid",
                name: "Edit Question",
                component: AddQuestion
            },
            {
                path: "/view-question/:id",
                name: "View Question",
                component: ViewQuestion
            },
            {
                path: "/lms-checklist/:uuid/:id",
                name: "Check List",
                component: CheckList
            },
            {
                path: "/add-lms-checklist/:id",
                name: "Add CheckList",
                component: AddCheckList
            },
            {
                path: "/edit-lms-checklist/:uuid",
                name: "Edit CheckList",
                component: AddCheckList
            },
            //===========My profile========
            {
                path: "/my-profile",
                name: "MyProfile",
                component: MyProfile,
            },


            // =============== Setting ===============
            // {
            //     path: "/setting",
            //     name: "Setting",
            //     component: Setting
            // },
            // {
            //     path: "/edit-setting",
            //     name: "Edit Setting",
            //     component: EditSetting
            // },
            // {
            //     path: "/contact",
            //     name: "Contact Us",
            //     component: ContactUs
            // },
            // {
            //     path: "/add-contact",
            //     name: "Add Contact",
            //     component: AddContact
            // },
            // {
            //     path: "/edit-contact/:uuid",
            //     name: "Edit Contact",
            //     component: AddContact
            // },

            // =============== Subscription Plan ===============
            {
                path: "/subscription-plan",
                name: "SubscriptionPlan",
                component: SubscriptionPlan
            },
            {
                path: "/add-subscription",
                name: "Add Subscription",
                component: AddSubscription
            },
            {
                path: "/edit-subscription-plan/:uuid",
                name: "Edit Subscription Plan",
                component: AddSubscription
            },


            //================ User ==============
            {
                path: "/users",
                name: "Users",
                component: Users
            },
            {
                path: "/add-user",
                name: "Add User",
                component: AddUser
            },

            {
                path: "/edit-users/:uuid",
                name: "Edit User",
                component: AddUser
            },
            //========Stages===========
            {
                path: "/users-stages/:uuid",
                name: "Stages",
                component: Stages
            },
            //=============Podcast============
            {
                path: "/podcasts",
                name: "Podcast",
                component: Podcast
            },
            {
                path: "/add-podcasts",
                name: "Add Podcast",
                component: AddPodcast
            },
            {
                path: "/edit-podcasts/:uuid",
                name: "Edit Podcast",
                component: AddPodcast
            },
             //=============Meditation============
             {
                path: "/meditations",
                name: "Meditation",
                component: Meditation
            },
            {
                path: "/add-meditations",
                name: "Add Meditation",
                component: AddMeditation
            },
            {
                path: "/edit-meditations/:uuid",
                name: "Edit Meditation",
                component: AddMeditation
            },
            //=============Affirmation============

            {
                path: "/affirmation",
                name: "Affirmation",
                component: Affirmation
            },
            {
                path: "/add-affirmation",
                name: "Add Affirmation",
                component: AddAffirmation
            },
            {
                path: "/edit-affirmation/:uuid",
                name: "Edit Affirmation",
                component: AddAffirmation
            },
            //================Onboarding======
            {
                path: "/onboarding",
                name: "Onboarding",
                component: Onboarding
            },
            {
                path: "/add-onboarding",
                name: "Add Onboarding",
                component: AddOnboarding
            },
            {
                path: "/edit-onboarding/:uuid",
                name: "Edit Onboarding",
                component: AddOnboarding
            },
            // =============== Role ===============
            {
                path: "/role",
                name: "Role",
                component: Role
            },
            {
                path: "/add-role",
                name: "Add Role",
                component: AddRole
            },
            {
                path: "/edit-role/:id",
                name: "Edit Role",
                component: AddRole
            },
            //===========Tools=============
            {
                path: "/tools/:uuid",
                name: "Tools",
                component: Tools
            },
            {
                path: "/menu",
                name: "Menu",
                component: Menu
            },


        ]
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/login',
                params: {
                    nextUrl: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        if (to.path === '/login') {
            if (localStorage.getItem('token') != null) {
                next({
                    path: '/admin',
                    params: {
                        nextUrl: to.fullPath
                    }
                })
            } else {
                next()
            }
        } else {
            next()
        }
    }
});

export default router;