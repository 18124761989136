import moment from "moment";

class HelperService {
  constructor() {
    this.accessLevel = new Map();
  }
  sortingPrevent(orderBy, id) {
    var temp = document.getElementById(id);
    if (temp) {
      temp.classList.add("sorting_" + orderBy.toLowerCase());
    }
  }
  sorting(sortBy, id) {
    var orderBy = "ASC";
    var className = "sorting_asc";
    var temp = document.getElementById(id);
    if (temp) {
      if (temp.className.includes("sorting_asc")) {
        className = "sorting_desc";
      }
      if (className == "sorting_asc") {
        temp.classList.add("sorting_asc");
        temp.classList.remove("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        temp.classList.add("sorting_desc");
        temp.classList.remove("sorting_asc");
        orderBy = "DESC";
      }
    }
    var obj = {
      order_by: orderBy,
      sort_by: sortBy,
    };
    return obj;
  }

  getFormatedDate(d) {
    return moment(d).format("DD MMM YYYY");
  }

  getFormatedDateAndTime(dt) {
    return moment.utc(dt).local().format("DD MMM YYYY, hh:mm A");
  }

  getFormatedDateNew(dt) {
    return moment.utc(dt).local().format("DD/MM/YYYY");
  }
  getNewFormatedDate(dt) {
    return moment.utc(dt).local().format("DD-MM-YYYY");
  }
  sendFormatedDate(d, type) {
    if (type == "date") {
      return moment(d).format("YYYY-MM-DD");
    } else if (type == "month_year") {
      return moment(d).format("YYYY-MM");
    } else if (type == "year") {
      return moment(d).format("YYYY");
    } else {
      return moment(d).format("YYYY-MM-DD");
    }
  }

  allowOnlyNumericValue(e) {
    var numbers = /^[0-9]$/;
    if (!e.key.match(numbers) && e.keyCode != 8) {
      e.preventDefault();
      return false;
    }
  }

  getTitleCase(value) {
    if (!value) {
      return "";
    } else {
      var str = "";
      var arr = value.split("_");
      for (var i in arr) {
        if (i == 0) {
          str =
            arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
        } else {
          str +=
            " " +
            arr[i].charAt(0).toUpperCase() +
            arr[i].substring(1).toLowerCase();
        }
      }
      return str;
    }
  }

  getHeight(n) {
    if (n) {
      // var realFeet = (n * 0.3937) / 12;
      // console.log("realFeet " + realFeet)
      // var feet = Math.floor(realFeet);
      // console.log("feet " + feet)
      // var inches = Math.round((realFeet - feet) * 12);
      // console.log("inches " + inches)
      // return feet.toString() + "'" + inches.toString() + '"';
      var realFeet = (n * 0.3937) / 12;
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      // alert(feet, inches)
      if (inches == 12) {
        feet = feet + 1;
        inches = 0;
        return feet + "'" + inches + '"';
      } else {
        return feet + "'" + inches + '"';
      }
    }
  }

  setAccessLevel(accessLevel) {
    this.accessLevel = accessLevel;
  }

  getAccessLevel(moduleName) {
    if (this.accessLevel.length > 0) {
      for (var i in this.accessLevel) {
        if (moduleName == this.accessLevel[i].access.code) {
          return this.accessLevel[i].access;
        }
      }
    }
  }

  getLeftMenuAccess(moduleName) {
    if (this.accessLevel.length > 0) {
      for (var i = 0; i < this.accessLevel.length; i++) {
        if (moduleName == this.accessLevel[i].code) {
          return this.accessLevel[i].can_read;
        }
      }
    }
  }

  hasAccessLevel() {
    return this.accessLevel.length > 0;
  }
  getText(text, length) {
    if (text && length && text.length > length) {
      return text.substring(0, length) + "...";
    }

    return text;
  }

  getFormatedDateTime(d) {
    if (d) {
      return moment(d).format("DD-MM-YYYY hh:mm A");
    } else {
      return;
    }
  }
  kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 100000).toFixed(1) + ""
      : Math.sign(num) * Math.abs(num);
  }
  showMenu(flag) {
    if (flag == "true") {
      document.getElementById("sidebar-div").style.width = "200px";
    } else {
      document.getElementById("sidebar-div").style.width = "0px";
    }
  }
}

export default new HelperService({});
