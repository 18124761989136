<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/podcasts')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} Podcast</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="podcastForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Title</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="title"
                              class="form-control"
                              placeholder="Title"
                              v-model="detail.title"
                              rules="required:title"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="title" class="text-danger" />
                          </div>
                        </div>
                        <!-- <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Ordering</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="ordering"
                              class="form-control"
                              placeholder="Ordering"
                              v-model="detail.ordering"
                              rules="required:ordering"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="ordering" class="text-danger" />
                          </div>
                        </div> -->

                        <div class="form-group col-md-6">
                          <label for="inputStage"> Stage</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputStage"
                            name="stage"
                            v-model="detail.stage_id"
                            rules="required:stage"
                          >
                            <option value="" disabled>Select Stage</option>
                            <option
                              v-for="(data, index) in list"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.title }}
                            </option>
                          </Field>
                          <ErrorMessage name="stage" class="text-danger" />
                        </div>

                        <div class="form-group col-md-6">
                          <label for="inputRole"> Status</label>
                          <span class="text-danger">*</span>
                          <Field
                            as="select"
                            aria-describedat=""
                            class="form-control"
                            id="inputRole"
                            name="status"
                            v-model="detail.status"
                            rules="required:status"
                          >
                            <option value="" disabled>Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </Field>
                          <ErrorMessage name="status" class="text-danger" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <label for="inputType">Audio</label>
                          <div class="text-left">
                            <a
                              v-if="detail.url"
                              class="fill-btn btn-style cursor-pointer"
                              v-on:click="showPopUp()"
                              >Listen</a
                            >
                            <!-- <img
                              :src="detail.gst_image ? detail.gst_image : ''"
                              class="img-fit-cover rounded-5"
                              id="cover"
                            /> -->
                          </div>
                          <div v-if="!detail.url">
                            <input
                              type="file"
                              name="img[]"
                              class="file"
                              id="audio"
                              style="display: none"
                              @change="selectedFile($event)"
                            />
                            <label for="audio"
                              ><a class="cursor-pointer"
                                >Browse... &nbsp; &nbsp;</a
                              >
                              <a>
                                {{
                                  detail.url || audioName
                                    ? audioName
                                    : "No File Selected"
                                }}</a
                              >
                            </label>
                          </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                          <label for="inputType">Thumbnail</label>
                          <div class="text-left" v-if="detail.thumbnail">
                            <img
                              style="width: 70px; height: 70px"
                              :src="detail.thumbnail ? detail.thumbnail : ''"
                              class="img-fit-cover rounded-5"
                              id="cover"
                            />
                          </div>
                          <div>
                            <input
                              type="file"
                              name="img[]"
                              class="file"
                              id="preview"
                              style="display: none"
                              @change="onChange($event)"
                            />
                            <label for="preview"
                              ><a class="cursor-pointer"
                                >Browse... &nbsp; &nbsp;</a
                              >
                              <a>
                                {{
                                  detail.thumbnail || thumbnailName
                                    ? thumbnailName
                                    : "No File Selected"
                                }}</a
                              >
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Duration</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="duration"
                              class="form-control"
                              placeholder="Duration"
                              v-model="detail.duration"
                              rules="required:duration"
                              :validateOnInput="true"
                            />
                            <ErrorMessage
                              name="duration"
                              class="text-danger"
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12">
                          <div class="form-group">
                            <label>Content</label>
                            <span class="text-danger">*</span>
                            <Field
                              as="textarea"
                              name="content"
                              class="form-control"
                              placeholder="Content"
                              v-model="detail.content"
                              rules="required:content"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="content" class="text-danger" />
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TextModal ref="textModal" width="100">
          <button
            type="button"
            class="close"
            v-on:click="$refs.textModal.closeModal"
            style="margin-bottom: 106px; margin-top: -21px; margin-right: 4px"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div>
            <audio
              v-if="detail.url"
              style="margin: 40px; margin-left: 97px"
              controls="true"
            >
              <source :src="detail.url" id="player" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <h3 v-if="!detail.url" style="margin: 40px; margin-left: 115px">
              No Audio Available
            </h3>
          </div>
        </TextModal>
      </div>
    </div>
  </div>
</template>
  <script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
export default {
  name: "AddPodcast",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      list: [],
      imageFile: undefined,
      audioName: "",
      thumbnailName: "",
      detail: {
        ordering: "1",
        is_optional: "1",
      },
      file: undefined,
      image: undefined,
    };
  },
  mounted() {
    this.getList();
    this.$helperService.showMenu("true");
    if (this.uuid) {
      this.getDetail();
    }
  },
  methods: {
    getList() {
      this.$api
        .getAPI({
          _action: "/stages",
        })
        .then((res) => {
          this.list = res.list;
          if (this.detail.stage_id != null) {
            this.stage_id = this.detail.stage_id;
          }
        })
        .catch(() => {});
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/podcast/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "/podcast/" + this.uuid,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.file) {
              this.uploadAudio(res.id, this.file);
              return;
            }
            if (this.image) {
              this.uploadImage(res.id, this.image);
              return;
            }
            this.$notify({
              type: "success",
              text: "Podcast updadted successfully",
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/podcast",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.file) {
              this.uploadAudio(res.id, this.file);
              return;
            }

            this.$notify({
              type: "success",
              text: "Podcast added successfully",
            });
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    uploadAudio(id, file) {
      if (this.file) {
        this.$api
          .uploadImageAPI({
            _action: "/podcast/" + id + "/audio",
            _key: "file",
            _file: file,
            _body: {
              id: id,
            },
          })
          .then((res) => {
            if (this.image) {
              this.uploadImage(res.uuid, this.image);
              return;
            }
            this.$notify({
              type: "success",
              text: "Audio uploaded successfully",
            });
            this.$router.go(-1);
          });
      } else {
        this.$router.go(-1);
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (files[0].type.toLowerCase() != "audio/mpeg") {
        alert("Invalid file formate, please use audio file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      // var size = e.target.files[0].size / 1024 / 1024
      if (size > 10) {
        this.$notify({
          type: "error",
          text: "File must be less then 10 MB",
        });
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      this.audioName = files[0].name;
      document.getElementById("audio").src = tmppath;
    },
    showPopUp() {
      this.$refs.textModal.showModal();
    },
    uploadImage(uuid, file) {
      if (this.image) {
        this.$api
          .uploadImageAPI({
            _action: "/podcast/" + uuid + "/thumb",
            _key: "file",
            _file: file,
            _body: {
              id: uuid,
            },
          })
          .then(() => {
            this.$notify({
              type: "success",
              text: "Thumbnail uploaded successfully",
            });
            this.$router.go(-1);
          });
      } else {
        this.$router.go(-1);
      }
    },
    onChange(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png" &&
        files[0].type.toLowerCase() != "image/svg+xml"
      ) {
        alert("Invalid file formate, please use jpeg,png or svg file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      // var size = e.target.files[0].size / 1024 / 1024
      if (size > 1) {
        this.$notify({
          type: "error",
          text: "File must be less then 1 MB",
        });
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.image = files[0];
      this.thumbnailName = files[0].name;
      document.getElementById("preview").src = tmppath;
    },
  },
};
</script>

