<template>
  <div>
    <div
      class="login-bg"
      style="
        background: linear-gradient(270deg, #d35bc1 0.8%, #f55bb0 98.48%);
        border-radius: 0px 20px 20px 0px;
      "
    >
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-lg-5 col-sm-6 fl-none">
            <div class="posAbs">
              <router-link to="/login">
                <a style="color: #000"><i class="fa fa-arrow-left"></i></a>
              </router-link>
            </div>
            <!-- <div class="text-center" style="color: black; font-size: 50px">
              SOS
            </div> -->
            <div class="text-center">
              <img src="/img/forgot2.png" alt="" />
            </div>
            <div style="margin-bottom: 18px" class="forgot-ps pb-0">
              Forgot Password?
            </div>
            <div class="enter-your">
              Please enter your registered<br />Email.
            </div>
            <Form @submit="forgotPassword()">
              <div class="form-group">
                <Field
                  type="text"
                  name="email"
                  class="inputfield font-weight-bold"
                  placeholder="Registered Email"
                  v-model="email"
                  rules="required:email|email"
                  :validateOnInput="true"
                />
                <ErrorMessage name="email" class="text-danger" />
              </div>
              <div class="verification mb-4 font-weight-bold">
                We will send verification code on your registered<br />email.
              </div>
              <div class="text-center">
                <button class="send-btn" id="forgot-password-button">
                  Send
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      email: "",
    };
  },
  mounted() {},
  methods: {
    forgotPassword() {
      var obj = {
        email: this.email,
      };
      this.$api
        .postAPI({
          _action: "/forgot/password",
          _body: obj,
          _buttonId: "forgot-password-button",
        })
        .then((res) => {
          // this.$router.push('/login')
          this.$notify({
            type: "success",
            text: "Email is sent to your registered email",
          });
          if (res) {
            localStorage.setItem("email", this.email);
            this.$router.push("/otp");
          }
          // this.$router.push("/reset-password?T="+res.token);
        });
    },

    // forgotPassword() {
    //   var obj = {
    //     email: this.email,
    //   };
    //   this.$api
    //     .postAPI({
    //       _action: "/forgot/password",
    //       _body: obj,
    //       _buttonId: "forgot-password-button",
    //     })
    //     .then((res) => {
    //       if (res) {
    //         localStorage.setItem("email", this.email);
    //         this.$router.push("/otp");
    //       }
    //     })
    //     .catch(() => {});
    // },
  },
};
</script>