<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4"
            >
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.push('/users')"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} User</h4>
            </div>
          </div>
        
        </div>
        <div>
          <ul
            class="nav nav-pills col-12 row p-0 m-0"
            id="myTab"
            role="tablist"
            v-if="uuid"
          >
            <li class="nav-item cursor-pointer mb-3 col-6 p-0 m-0">
              <a
                class="nav-link active"
                data-toggle="tab"
                style="text-align: center; align-content: start"
                :class="{ active: currentTab == 'BASIC_INFO' }"
                v-on:click="changeTab('BASIC_INFO')"
              >
                <span> Basic Information</span></a
              >
            </li>
            <li class="nav-item cursor-pointer col-6 p-0 m-0">
              <a
                class="nav-link"
                data-toggle="tab"
                style="
                  text-align: center;
                  align-content: end;
                  background-color: white;
                "
                v-on:click="
                  $router.push('/users-stages/'  + this.uuid)
                "
              >
                <span>Stages</span></a
              >
            </li>
          </ul>
          </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="row">
                        <!-- <div class="col-lg-12 col-sm-12 mb-3">
                          <button
                            v-on:click="removeImage(detail.uuid)"
                            type="button"
                            class="close text-danger"
                            style="
                              float: right;
                              position: relative;
                              right: 333px;
                              top: 11px;
                            "
                          >
                            <img src="/images/close-btn.png" width="18" />
                          </button>
                          <div class="text-center mb-4">
                            <img
                              :src="
                                detail.image && detail.image != null
                                  ? detail.image
                                  : '/images/placeholder.gif'
                              "
                              id="preview"
                              class="img-thumbnail"
                              style="
                                border-radius: 100px;
                                width: 120px;
                                height: 120px;
                              "
                            /><br />
                            <div id="msg"></div>
                            <div
                              id="image-form"
                              style="margin: 0 auto; width: 90px"
                            >
                              <input
                                type="file"
                                name="img[]"
                                class="file"
                                id="pickFile"
                                @change="selectedFile($event)"
                                accept="image/*"
                                style="display: none"
                              />
                              <div class="input-group my-1">
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  placeholder="Upload File"
                                  id="file"
                                  style="display: none"
                                />
                                <div class="changePic text-center" style="bottom: -25px">
                                  <label for="pickFile"> Change</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Customer Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Customer Name"
                              v-model="detail.name"
                              rules="required:customer name"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="name" class="text-danger" />
                          </div>
                        </div>
                       
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="email"
                              name="email"
                              class="form-control"
                              placeholder="Email"
                              v-model="detail.email"
                              rules="required:email|email"
                              :validateOnInput="true"
                            />
                            <ErrorMessage name="email" class="text-danger" />
                            <div
                              data-toggle="modal"
                              data-target="#changePassword"
                              style="
                                float: right;
                                cursor: pointer;
                                text-decoration: underline;
                              "
                              v-show="detail.uuid"
                              v-on:click="showChangePasswordPopup"
                            >
                              <a>Change Password</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Mobile number</label>

                            <Field
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              v-slot="{ field }"
                              v-model="detail.phone_number"
                              rules=":contact number"
                              :validateOnInput="true"
                            >
                              <PhoneCode
                                id="mobile"
                                placeholder="Mobile Number"
                                v-model:countryCode="detail.country_code"
                                v-model="detail.phone_number"
                                v-model:dialCode="detail.dial_code"
                                v-bind="field"
                              />
                              <ErrorMessage name="phone" class="text-danger" />
                            </Field>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!uuid">
                          <div class="form-group">
                            <label>Password </label>
                            <span class="text-danger">*</span>
                            <Field
                              :type="passwordFieldType"
                              name="password"
                              class="form-control"
                              placeholder="Password"
                              rules="required:password|minLength:6"
                              :validateOnInput="true"
                              v-model="detail.password"
                            />
                            <span
                              style="
                                position: absolute;
                                right: 20px;
                                top: 34px;
                                z-index: 999;
                              "
                            >
                              <a
                                class="psw-icon cursor-pointer"
                                v-on:click="switchVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </span>
                            <ErrorMessage name="password" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!uuid">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <span class="text-danger">*</span>
                            <Field
                              :type="passwordFieldType2"
                              placeholder="Confirm Password"
                              name="name_ccpassword"
                              class="form-control"
                              rules="required:confirm password|confirmed:@password"
                              v-model="detail.confirm_password"
                              :validateOnInput="true"
                            />
                            <span
                              style="
                                position: absolute;
                                right: 20px;
                                top: 34px;
                                z-index: 999;
                              "
                            >
                              <a
                                class="psw-icon cursor-pointer"
                                v-on:click="switchPasswordVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </span>
                            <ErrorMessage
                              name="name_ccpassword"
                              class="text-danger"
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Change Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field
              :type="passwordFieldType"
              name="name_password"
              class="form-control"
              placeholder="New Password"
              v-model="password"
              rules="required:password|minLength:6"
              :validateOnInput="true"
            />
            <span
              style="position: absolute; right: 22px; top: 34px; z-index: 999"
            >
              <a class="psw-icon cursor-pointer" v-on:click="switchVisibility"
                ><img :src="image" width="20"
              /></a>
            </span>
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field
              :type="passwordFieldType2"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true"
            />
            <span
              style="position: absolute; right: 20px; top: 34px; z-index: 999"
            >
              <a
                class="psw-icon cursor-pointer"
                v-on:click="switchPasswordVisibility"
                ><img :src="images" width="20"
              /></a>
            </span>
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            class="fill-btn"
            id="change-password-button"
            style="width: 20%; border-radius: 5px"
          >
            Save
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px"
          >
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "AddAdmin",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    PhoneCode,
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
      currentTab: "",
      roleList: [],
      detail: {
        role_id: "",
        password: "",
        confirm_password: "",
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png",
    };
  },
  mounted() {
    this.changeTab("BASIC_INFO");
    this.$helperService.showMenu("true");
    if (this.uuid) {
      this.getDetail();
    }

  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
    removeImage(uuid) {
      if (uuid) {
        this.$api
          .deleteAPI({
            _action: "/remove-profile-image/" + uuid,
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.getDetail();
          })
          .catch(() => {});
      }
    },
   
    getDetail() {
      this.$api
        .getAPI({
          _action: "/user/" + this.uuid,
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    save() {
      if (this.uuid) {
        this.$api
          .putAPI({
            _action: "/user/" + this.uuid,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/admin/signup",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.password = this.password;
      obj.uuid = this.uuid;
      this.$api
        .putAPI({
          _action: "/user/" + this.uuid +"/change",
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then((res) => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchPasswordVisibility() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
      this.images =
        this.images === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    // selectedFile(e) {
    //   let files = e.target.files;
    //   if (
    //     files[0].type.toLowerCase() != "image/jpeg" &&
    //     files[0].type.toLowerCase() != "image/png"
    //   ) {
    //     alert("Invalid file formate, please use jpeg or png file.");
    //     return;
    //   }
    //   const size = (files[0].size / 1024 / 1024).toFixed(2);
    //   // var size = e.target.files[0].size / 1024 / 1024
    //   if (size > 1) {
    //     this.$notify({
    //       type: "error",
    //       text: "File must be less then 1 MB",
    //     });
    //     return;
    //   }
    //   var tmppath = URL.createObjectURL(files[0]);
    //   this.file = files[0];
    //   document.getElementById("preview").src = tmppath;
    // },
  },
};
</script>