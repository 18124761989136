<template>
    <div>
        <div class="page-wrapper">
            <div class="page-content-wrapper">
                <div class="shadow-btm">
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
                            <a class="pr-2 text-dark cursor-pointer"
                                v-on:click="$router.push('/view-question/' + this.id)"><span class="font-24 mr-2"><img
                                        src="/images/back-arrow.svg" width="18" /></span>
                            </a>
                            <h4 class="mb-0 lg-bld">{{ uuid ? "Edit" : "Add" }} Question</h4>
                        </div>
                    </div>
                </div>
                <div class="page-content">
                    <div class="card-body py-0 px-0">
                        <div class="row">
                            <div class="col-lg-10 col-sm-10 float-none">
                                <div class="card border mb-0 radius-15">
                                    <div class="card-body">
                                        <Form itemref="lessonForm" @submit="save()">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <label>Title</label>
                                                        <span class="text-danger">*</span>
                                                        <Field type="text" name="title" class="form-control"
                                                            placeholder="Title" v-model="detail.title"
                                                            rules="required:title" :validateOnInput="true" />
                                                        <ErrorMessage name="title" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputType">Type</label>
                                                    <span class="text-danger">*</span>
                                                    <Field as="select" aria-describedat="" class="form-control"
                                                        id="inputType" name="type" v-model="detail.type"
                                                        rules="required:type">
                                                        <option value="" disabled>Select Type</option>
                                                        <option value="TEXT">Text</option>
                                                        <option value="SINGLE_SELECTION">Single Selection</option>
                                                        <option value="MULTI_SELECTION">Multi Selection</option>
                                                    </Field>
                                                    <ErrorMessage name="type" class="text-danger" />
                                                </div>
                                                <div class="col-lg-6 col-sm-6 row mb-4" v-show="detail.type == 'SINGLE_SELECTION' ||
                                                    detail.type == 'MULTI_SELECTION'
                                                    ">
                                                    <div class="col-lg-11">
                                                        <label>Option</label>
                                                        <Field type="text" name="option" class="form-control"
                                                            placeholder="Option" v-model="option" />
                                                    </div>
                                                    <div class="col-lg-1" style="top: 31px">
                                                        <i v-on:click="saveOption()" class="fa fa-plus cursor-pointer"
                                                            aria-hidden="true"
                                                            style="font-size: 20px;height: 30px;padding: 6px;"></i>
                                                    </div>
                                                </div>
                                                <div class="table-responsive col-lg-12" v-show="detail.type == 'SINGLE_SELECTION' ||
                                                    detail.type == 'MULTI_SELECTION'
                                                    ">
                                                    <table class="table">
                                                        <thead>
                                                            <tr role="row">
                                                                <th>Title</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(data, index) in detail.list" :key="index">
                                                                <td>
                                                                    <Field :id="'title_' + index" type="text"
                                                                        :name="'title_' + index" class="form-control"
                                                                        placeholder="Option" v-model="data.title" />
                                                                </td>
                                                                <td>
                                                                    <a class="mr-2 text-danger cursor-pointer"
                                                                        v-on:click="removeOption(index)"><i
                                                                            class="bx bx-trash"></i></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <label for="inputRole"> Status</label>
                                                    <span class="text-danger">*</span>
                                                    <Field as="select" aria-describedat="" class="form-control"
                                                        id="inputRole" name="status" v-model="detail.status"
                                                        rules="required:status">
                                                        <option value="" disabled>Select Status</option>
                                                        <option value="ACTIVE">Active</option>
                                                        <option value="INACTIVE">Inactive</option>
                                                    </Field>
                                                    <ErrorMessage name="status" class="text-danger" />
                                                </div>
                                                <div class="col-lg-12 col-sm-12 text-center mt-2">
                                                    <button id="save-button" class="fill-btn px-4 ml-3">
                                                        Save
                                                    </button>
                                                    <button type="button" class="fil-btn px-4 ml-3"
                                                        v-on:click="$router.go(-1)" id="cancel-button">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
    name: "AddQuestion",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            id: this.$route.params.id,
            uuid: this.$route.params.uuid,
            detail: {
                lesson_id: "",
                list: [],
            },
            option: "",
        };
    },
    mounted() {
        this.$helperService.showMenu("true");
        if (this.uuid) {
            this.getDetail();
        }
    },
    methods: {
        saveOption() {
            var obj = {
                title: this.option,
            };
            this.detail.list.push(obj);
            this.option = "";
        },
        removeOption(index) {
            this.detail.list.splice(index, 1);
        },
        getDetail() {
            this.$api
                .getAPI({
                    _action: "/lesson/" + this.id + "/question/" + this.uuid,
                })
                .then((res) => {
                    this.detail = res.info;
                    if (res.info.value) {
                        this.detail.list = JSON.parse(res.info.value);
                    } else {
                        this.detail.list = [];
                    }
                })
                .catch(() => { });
        },
        save() {
            if (this.uuid) {
                this.$api
                    .putAPI({
                        _action: "/lesson/" + this.id + "/question/" + this.uuid,
                        _body: this.detail,
                        _buttonId: "save-button",
                    })
                    .then(() => {
                        this.$router.push('/view-question/' + this.id);
                    })
                    .catch(() => {
                    });
            } else {
                if (this.id) {
                    this.detail.lesson_id = this.id;
                }
                this.$api
                    .postAPI({
                        _action: "/lesson/" + this.id + "/question",
                        _body: this.detail,
                        _buttonId: "save-button",
                    })
                    .then(() => {
                        this.$router.push('/view-question/' + this.id);
                    })
                    .catch(() => {
                    });
            }
        },

    },
};
</script>