<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div
            class="row col-lg-6 col-sm-6 my-auto d-flex align-items-center mb-4"
          >
            <a
              class="pr-2 text-dark cursor-pointer"
              v-on:click="$router.push('/role')"
              ><span class="font-24 mr-2"
                ><img src="/images/back-arrow.svg" width="18"
              /></span>
            </a>
            <h4 class="mb-0 lg-bld">{{ id ? "Edit" : "Add" }} Role</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div
                class="col-lg-4 col-sm-4 form-group row"
                style="margin-left: 3px"
              >
                <label>Role Name</label>
                <input
                  type="text"
                  name="role_name"
                  class="form-control"
                  placeholder="Role Name"
                  v-model="detail.role_name"
                />
              </div>
              <div class="form-group col-md-8">
                <label for="inputRole"> Status</label>
                <span class="text-danger">*</span>
                <Field
                  as="select"
                  aria-describedat=""
                  class="form-control"
                  id="inputRole"
                  name="status"
                  v-model="detail.status"
                  rules="required:status"
                >
                  <option value="" disabled>Select Status</option>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Field>
                <ErrorMessage name="status" class="text-danger" />
              </div>
            </div>
            <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="dataTables_wrapper dt-bootstrap4">
                    <table
                      class="table table-striped table-bordered dataTable"
                      style="width: 100%"
                      role="grid"
                      aria-describedby="example_info"
                    >
                      <thead>
                        <tr>
                          <th>Access to</th>
                          <!-- <th>All</th> -->

                          <th>Add</th>
                          <th>View</th>

                          <th>Edit</th>
                          <th>Delete</th>
                          <th>Print</th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr
                          v-for="(data, index) in this.detail.role_access"
                          :key="index"
                        >
                          <td>{{ data.menu }}</td>
                          <!-- <td><input type="checkbox"  v-model="data.is_all" true-value="1" false-value="0"/></td> -->
                          <td>
                            <input
                              type="checkbox"
                              v-model="data.can_create"
                              v-on:change="autoUpdate(data)"
                              true-value="1"
                              false-value="0"
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              v-model="data.can_read"
                              true-value="1"
                              false-value="0"
                              :disabled="data.can_read == null"
                            />
                          </td>

                          <td>
                            <input
                              type="checkbox"
                              v-model="data.can_update"
                              v-on:change="autoUpdate(data)"
                              true-value="1"
                              false-value="0"
                              :disabled="data.can_update == null"
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              v-model="data.can_delete"
                              v-on:change="autoUpdate(data)"
                              true-value="1"
                              false-value="0"
                              :disabled="data.can_delete == null"
                            />
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              v-model="data.can_print"
                              true-value="1"
                              false-value="0"
                              :disabled="data.can_print == null"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <ErrorComponent @retry="getList(0)" ref="errorComponent" />

                  <div class="col-lg-12 col-sm-12 text-center mt-2">
                    <button
                      id="save-button"
                      class="fill-btn px-4 ml-3"
                      v-on:click="save"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="fil-btn px-4 ml-3"
                      v-on:click="$router.go(-1)"
                      id="cancel-button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import { Field, ErrorMessage } from "vee-validate";
export default {
  name: "AddRole",
  components: {
    ErrorComponent,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      searchObj: {
        keyword: "",
      },
      detail: {
        role_name: "",
        status: "",
        role_access: [],
      },
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    this.getList();

    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    autoUpdate(data) {
      if (data.can_create == "1" || data.can_update == "1" || data.can_delete == '1') {
        data.can_read = "1";
      } else if(data.can_create == "0" || data.can_update == "0" || data.can_delete == '0'){
        data.can_read = "0";
      }
    },
    getList() {
      this.$refs.errorComponent.updateListLoader(true);
      this.detail.role_access = [];
      this.$api
        .getAPI({
          _action: "/menus",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.detail.role_access = res.list;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No data available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },

    getDetail() {
      this.$api
        .getAPI({
          _action: "/role/" + this.id,
        })
        .then((res) => {
          this.detail = res;

          this.detail.role_access = res.list;
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/role/" + this.id,
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/role",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
  },
};
</script>